<script setup>
import {Link} from '@inertiajs/vue3';
import Primary from "@/Components/Primary.vue";

const props = defineProps({
    href: {
        type: String,
        required: true,
    },
    inverted: {
        type: Boolean,
        required: false,
    },
    link: {
        type: Boolean,
        default: true,
        required: false,
    }
});
</script>

<template>
    <Primary :is="link ? Link : 'a'" :href="props.href" :inverted="inverted ?? false">
        <slot/>
    </Primary>
</template>
